import React from "react"
import "../index.css"

import { Box } from "@chakra-ui/react"
import Footer from "../Home/Footer"
import HomeNavBar from "../Home/HomeNavBar"
import WantMoreInWela from "./WantMoreInWela"

const event = () => {
  return (
    <>
      <HomeNavBar />

      <Box pt={"20"} bgColor="#FBFBFB">
        <Box
          px={{
            "2xl": "27rem",
            xl: "20rem",
            lg: "15rem",
            sm: "5rem",
            base: "1rem",
          }}
        >
          <Box color="#9B9B9B" fontSize={15} py="2">
            EVENTS | JANUARY 02, 2022
          </Box>
          <Box fontWeight={600} fontSize="25" letterSpacing={"wide"}>
            Increase Parental Involvement
          </Box>
          <Box color="#9B9B9B" fontSize={15} py="2" mb="3">
            Free automated reminders help participants keep up with their
            responsibilities, saving you time and money.
          </Box>
          {/* <Image src={sampleImg} w="100%" h="400" /> */}
          <Box fontWeight={600} fontSize={15} mb="3">
            The classes in schools are among those affected by the coronavirus
            or COVID-19 crisis in the Philippines. Amid the country recording
            cases of the disease, classes in schools were suspended and actual
            graduation rites were postponed.
          </Box>
          <Box fontWeight={600} fontSize={15} mb="3">
            Supposedly, the opening of classes in schools under the Department
            of Education is June. As early as May, the enrollment takes place
            and the teachers and students are preparing the schools for a new
            school year. However, due to the COVID-19 crisis in PH now, the
            enrollment was moved to June. It is a month-long enrollment for
            elementary and high school students with the first two (2) weeks
            featuring the remote registration.
          </Box>
          <Box fontWeight={600} fontSize={15} pb="10">
            Supposedly, the opening of classes in schools under the Department
            of Education is June. As early as May, the enrollment takes place
            and the teachers and students are preparing the schools for a new
            school year.
          </Box>
        </Box>
      </Box>

      <WantMoreInWela />
      <Footer />
    </>
  )
}

export default event
